import React from 'react'
import { Link } from 'gatsby'
import { HomePanels } from '$components'
import Layout from '../components/layout'
import SEO from '../components/seo'
import logo from '../images/bel-logo.svg'
import styled from 'styled-components'
import Helmet from 'react-helmet'

const Logo = styled.h1`
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1;

  @media (min-width: 1024px) {
    top: 25px;
    left: 30px;
  }

  img {
    display: block;
  }
`

export const LogoType = styled.h1`
  font-size: 30px;
  line-height: 1;
  letter-spacing: -0.28px;
  color: #ffbe1d;
  margin: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 26px;
  z-index: 2;
  text-transform: lowercase;

  @media (min-width: 768px) {
    font-size: 50px;
    top: 40px;
  }

  @media (min-width: 1024px) {
    display: inline;
    font-size: 50px;
    color: #e8d8d9;
    top: 40px;
  }

  a {
    color: #ffbe1d;
    text-decoration: none;

    @media (min-width: 1024px) {
      color: #e8d8d9;
    }
  }
`

const Home = () => (
  <Layout noFooter="true" noHeader="true">
    <SEO title="Freelance motion designer in melbourne" />

    <Helmet
      bodyAttributes={{
        class: 'body--home',
      }}
    />

    <LogoType style={{ margin: 0 }}>
      <Link to="/">bel.</Link>
    </LogoType>

    <HomePanels name="Home Panel" />
  </Layout>
)

export default Home
